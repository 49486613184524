import {BaseToolProcessor} from '../BaseToolProcessor';

/**
 * Code processor
 */
export class ImageGeneratorProcessor extends BaseToolProcessor {
    toolName = 'ai:image-generator';

    /**
     * Process incoming event
     */
    async processEvent() {
        const syncResponse = this.block?.properties?.syncResponse === 1;

        if(this.app.renderer.a2u.runMode === "debug") {
            return this.debugResponse({
                ...(!syncResponse ? {'job-id': '1'} : {data: {}}),
                error: {message: `Error calling tool ${this.toolName}`},
            });
        }

        const prompt = this.context.interpretString(this.block?.properties?.prompt) || this.block?.properties?.prompt;

        const saveFileLocally = this.block?.properties?.saveFileLocally === 1;
        const facePath = this.context.getValue(this.block?.properties?.face) || null;
        const face = !facePath ? null : await this.context.renderer.a2u.assetBase64(facePath) || null;
        const width = this.block?.properties?.width;
        const height = this.block?.properties?.height;
        const seed = parseInt(this.block?.properties?.seed) || 1234;
        const scale = parseInt(this.block?.properties?.scale) || 1;
        const service = parseInt(this.block?.properties?.service) || 'api-market';

        if (!prompt || !width || !height) {
            this._debugLog('Missing properties: prompt, width, height, seed');

            return this.processOutgoingLinks(this.context, this.block.id, 'Missing properties: prompt, width, height, seed', 'error');
        }

        try {
            // Call the tool
            const result = await this.context?.renderer?.a2u?.callTool(this.toolName, {
                prompt,
                width,
                height,
                seed,
                face,
                scale,
                service,
            }, { sync: syncResponse, saveFileLocally });

            // Process the result
            if (syncResponse) {
                const varToSaveImageUrl = this.block.properties?.varToSaveImageUrl;

                result.url = saveFileLocally && result?.url
                  ? await this.context.renderer.a2u.saveLocally(result.url)
                  : result?.url;

                if (varToSaveImageUrl) {
                    this.context.setOperationValue(varToSaveImageUrl, 'set', {
                        valueType: 'static',
                        value: result.url,
                    });
                }

                this.processOutgoingLinks(this.context, this.block.id, result.url, 'data');
            } else {
                // Process the result
                if (!result?.jobId) {
                    throw 'Job ID not found';
                }

                const varToSaveJobId = this.block.properties?.varToSaveJobId;

                if (varToSaveJobId) {
                    this.context.setOperationValue(varToSaveJobId, 'set', {
                        valueType: 'static',
                        value: result.jobId,
                    });
                }

                this.processOutgoingLinks(this.context, this.block.id, result.jobId, 'job-id');
            }
        } catch (e) {
            console.error(`Error calling tool ${this.toolName}`, e);

            this._debugLog(e);

            this.processOutgoingLinks(this.context, this.block.id, null, 'error');
        }
    }
}
