import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * Code processor
 */
export class InAppPurchaseProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param event
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    processEvent(event, data) {

        // get product id
        const productId = this.context.getValue(this.block?.properties?.product);

        // Check if product id is set
        if(!productId) {
            console.error("Product id is not set");

            // Log error
            this.logError(new Error('Product id is not set'));

            return this.processOutgoingLinks(this.context, this.block.id, false, "error");
        }

        const targetPlatform = this.context.renderer.a2u.source.modules[this.context.renderer.a2u.getModuleId()]?.type === 'web' ? 'web' : 'mobile';

        // Debug list of goods for web environment
        if(this.context.renderer.a2u.getPlatform() === "web" && targetPlatform !== 'web') {
            return this.debugResponse({success: {}, cancel: {}, error: {}}, `In app purchase: ${productId}`)
        }

        // Show loading
        this.app.$q.loading.show();

        // Disallow restore event
        this.context.renderer.a2u.allowRestore(false);

        // Get ad instance
        const iapManager = this.context.renderer.a2u.getDevice()?.getPluginInstance?.("IAPManager");

        // Get checkout mode
        // const checkoutMode = this.block?.properties?.checkoutMode || 'hosted';
        const checkoutMode = 'embedded';

        // Get products list
        iapManager.purchase(productId, checkoutMode).then(async (res) => {
            console.log("Purchase result", res);
            return this.processOutgoingLinks(this.context, this.block.id, false, "success");
        }).catch(err => {
            console.error("Purchase error", err);

            // Log error
            if (err?.code !== "cancel") {
                this.logError(err);
            }

            if (err?.code === 'purchase_timeout') {
                if (!this.context.renderer.a2u?.componentsManager?.hasOutgoingLinks(this.block.id, 'purchase-timeout')) {
                    this.context.$q.dialog({
                        message: this.context.interpretString({
                            isLocalizable: true,
                            localeAlias: `${this.context.renderer.a2u.getModuleId()}.iap.purchase.timeout`,
                            value: 'Thank you, access will be activated after payment is completed.',
                        }),
                    });
                }

                return this.processOutgoingLinks(this.context, this.block.id, false, 'purchase-timeout');
            } else if (err?.code === 'cancel') {
                return this.processOutgoingLinks(this.context, this.block.id, false, 'cancel');
            }

            return this.processOutgoingLinks(this.context, this.block.id, err.message, 'error');
        }).finally(() => {
            // Hide loading
            this.app.$q.loading.hide();

            // Allow restore event
            this.context.renderer.a2u.allowRestore(true);
        });
    }
}
