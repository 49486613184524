<template>
  <ab-flow-base-cmp :block="block" class="lottie-editor-cmp" :class="classesString" :style="stylesString">
    <q-spinner-tail v-if="!source_data && !source_url" class="q-mx-auto q-my-auto" color="primary" size="2em" />
    <div v-else class="lottie-animation-wrapper">
      <LottiePlayer
        class="lottie-animation-container"
        :key="keyNumber"
        :animation-url="source_url"
        :animation-data="source_data"
        :loop="isLottieLoop"
        :fit="fit"
        :position="position"
      />
    </div>
  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "../../Containers/Designer/AbFlowBaseCmp.vue";
import {renderMixins} from "../../renderMixins";
import LottiePlayer from './LottiePlayer.vue';

export default {
  mixins: [renderMixins],
  components: {AbFlowBaseCmp, LottiePlayer},
  props: ['block'],
  name: "LottieEditorCmp",
  data() {
    return {
      keyNumber: 0,
    };
  },

  computed: {
    /**
     * lottie source
     * @return {string}
     */
    lottie_source() {
      // Get path
      return this.renderer.a2u.assetPath(this.getValue(this.block?.properties?.lottie))
    },

    /**
     * lottie source url
     * @return {string|undefined}
     */
    source_url() {
      return typeof this.lottie_source === 'string' ? this.lottie_source : undefined
    },

    /**
     * lottie source data
     * @return {string|undefined}
     */
    source_data() {
      return typeof this.lottie_source === 'object' ? this.lottie_source : undefined
    },

    /**
     * Is lottie loop
     * @return {boolean}
     */
    isLottieLoop() {
      return this.block.properties?.loop === 1 || false;
    },

    /**
     * Computes the fitting mode for the Lottie animation.
     * @return {string} The fitting mode, default is 'contain'.
     */
    fit() {
      return this.block.properties?.fitting || 'contain';
    },

    /**
     * Computes the position for the Lottie animation.
     * @return {string} The position, default is 'center'.
     */
    position() {
      return this.block.properties?.position || 'center';
    },
  },

  watch: {
    lottie_source() {
      this.keyNumber++;
    },
  },
}

</script>

<style lang="scss">

.lottie-editor-cmp {
  display: flex;
  width: 100%;
  height: 100%;
  min-width: 1em;
  min-height: 1em;
  z-index: 0;

  .lottie-animation-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .lottie-animation-container {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}

</style>
