<template>
    <data-provider v-if="isReady" storage-key="fragment" :data="storage">
      <fragment-renderer-cmp ref="fragment" :block="block"/>
    </data-provider>
</template>

<script>

import {reactive} from 'vue';
import {AbStorage} from "a2u-renderer-common/src/utils/abStorage";
import DataProvider from "../../DataViews/DataProvider/DataProvider.vue";
import FragmentRendererCmp from "../../Containers/Fragment/FragmentRendererCmp.vue";
import {renderMixins} from "../../renderMixins";

export default {
  mixins: [renderMixins],
  components: {FragmentRendererCmp, DataProvider},
  inject: ["renderer", "parentDiagram"],
  props: {
    block: {},
    incomingParams: {},
  },
  name: "FragmentEditorCmp",
  data: () => ({
    isReady: false,
  }),
  created() {
    this.$watch("stage", () => {
      console.log("FragmentEditorCmp state updated", this.block.id)
    })

    // Fragment is ready
    this.isReady = true
  },

  methods: {
    modalShown() {
      this.$nextTick(() => {
        this.$refs.fragment.isShown = true
      })
    }
  },

  computed: {
    storage() {
      // Create storage
      const storage = new AbStorage({
        initialStorage: this.renderer.a2u.source?.storage?.data?.[this.block.id],
        storageCreator: (data) => reactive(data),
      });

      // Set incoming params
      for(const [name, val] of Object.entries(this.getArguments(this.block.id) || {})) {
        if(val !== undefined) storage.set(name, val)
      }

      // Return storage
      return storage;
    }
  },
}

</script>
