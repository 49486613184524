import axios from 'axios';
import {nanoid} from 'nanoid';
import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * Code processor
 */
export class ShareDialogProcessor extends BaseComponentProcessor {

    /**
     * Construct parent
     * @param props
     */
    constructor(...props) {
        super(...props);
    }


    /**
     * Process incoming event
     * @param source
     * @param source_event
     * @param action
     * @param data
     */
    async processEvent() {

        // Get ad instance
        const shareManager = this.context.renderer.a2u.getDevice()?.getPlugin?.("Share");

        // Check if no shareManager - debug response
        if(!shareManager || (this.context.renderer.a2u.getPlatform() === 'web' && this.app.renderer.a2u.runMode === "debug") ) {
            // Get result
            return this.debugResponse({error: {}});
        }

        // Get url
        const urlPath = this.context.getValue(this.block?.properties?.url) || null;
        const url = urlPath ? await this.context.renderer.a2u.assetPathAsync(urlPath) : null;

        // Get files
        const files = this.context.getValue(this.block?.properties?.files) || null;

        // Get files url
        let filesUrl = files ? await this.context.renderer.a2u.assetPathAsync(files) : null;

        if (filesUrl && this.context.renderer.a2u.platform === 'android') {
            filesUrl = await this._getFileUrlOnAndroid(filesUrl);
        }

        // Defining the configuration for the "Share" plugin
        const shareCfg = {
            title: this.context.interpretString(this.block?.properties?.title),
            text: this.context.interpretString(this.block?.properties?.text),
            url,
            dialogTitle: this.context.interpretString(this.block?.properties?.dialogTitle),
            files: filesUrl ? [filesUrl] : undefined,
        };

        // Cleaning the configuration from empty values
        const finalShareCfg = Object.fromEntries(
          Object.entries(shareCfg).filter(([, v]) => !!v),
        );

        // Returning an error if the configuration ends up empty
        if (!Object.keys(finalShareCfg).length) {
            console.error('No resources have been specified to share', this.block)
            return this.processOutgoingLinks(this.context, this.block.id, false, 'error');
        }

        try {
            const result = await shareManager.share(finalShareCfg);
            console.log("Share result", result);
        } catch (e) {
            return this.processOutgoingLinks(this.context, this.block.id, false, 'error');
        }
    }

    /**
     * Retrieves the file URL on Android by downloading the file and saving it to the file system.
     * @param {string} url - The URL of the file to be downloaded.
     * @returns {Promise<string>} - A promise that resolves to the file URI.
     * @throws {Error} - Throws an error if the FileSystem plugin is not found.
     */
    async _getFileUrlOnAndroid(url) {
        try {
            // If the platform is not 'web', save the file in the file system
            const fileSystem = this.app.renderer.a2u.getDevice().getPlugin('FileSystem');

            if (!fileSystem) {
                throw new Error('FileSystem plugin not found');
            }

            const response = await axios.get(url, { responseType: 'blob' });
            const blob = response.data;

            const [,ext] = url.match(/\.([a-zA-Z0-9]+)$/);

            const fileName = `${nanoid(10)}.${ext}`;

            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.onloadend = async () => {
                    const base64data = reader.result.split(',')[1];

                    await fileSystem.writeFile(fileName, base64data, false, 'CACHE');

                    const fileUri = await fileSystem.getUri(fileName, 'CACHE');

                    resolve(fileUri);
                };
                reader.readAsDataURL(blob);
            });
        } catch (e) {
            console.log('Error while getting file url on android', e);

            return url;
        }
    }
}
