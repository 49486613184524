<template>
<div>
  <q-dialog v-model="jsonDialog.show" class="z-max">
    <q-card>
      <q-card-section>
        <pre>{{ jsonDialog.data }}</pre>
      </q-card-section>
    </q-card>
  </q-dialog>

  <q-markup-table dense>
    <thead>
    <tr>
      <th class="text-left">Time</th>
      <th class="text-left">Type</th>
      <th class="text-left">Message</th>
      <th class="text-left">Diagram</th>
      <th class="text-left">Component id</th>
      <th class="text-left">Component type</th>
      <th class="text-left">Component title</th>
      <th class="text-left">Properties</th>
      <th class="text-left">Incoming</th>
      <th class="text-left">Outgoing</th>
    </tr>
    </thead>
    <tbody>
    <tr v-if="!events.length">
      <td colspan="10" class="text-center text-grey">
        No events
      </td>
    </tr>
    <tr v-for="(event, idx) in events" :key="idx">
      <td>{{ event.time }}</td>
      <td><q-badge :label="event.type" :color="computeEventColor(event)" /></td>
      <td>{{ event.message }}</td>
      <td>{{ event.diagramName }}</td>
      <td>
        <q-chip
          :label="event.component.id"
          color="secondary"
          text-color="white"
          size="sm"
          clickable
          @click="goToComponent(event)"
        />
      </td>
      <td>{{ event.component.type }}</td>
      <td>{{ event.component.title }}</td>
      <td>
        <template v-if="event.component.properties">
          <q-btn
            class="q-px-sm"
            size="xs"
            label="Show"
            @click="showJsonDialog(event.component.properties)"
          />
        </template>
        <template v-else>
          <div class="text-grey">n/a</div>
        </template>
      </td>
      <td>
        <q-badge v-if="event.incoming.event" :label="event.incoming.event" />
        <span v-else class="text-grey">n/a</span>

        <q-btn
          v-if="event.incoming.data"
          class="q-px-sm q-ml-sm"
          size="xs"
          label="Data"
          @click="showJsonDialog(event.incoming.data)"
        />
      </td>
      <td>
        <q-badge v-if="event.outgoing.event" :label="event.outgoing.event" />

        <q-btn
          v-if="event.outgoing.data"
          class="q-px-sm q-ml-sm"
          size="xs"
          label="Data"
          @click="showJsonDialog(event.outgoing.data)"
        />
      </td>
    </tr>
    </tbody>
  </q-markup-table>

  <div class="flex justify-end">
    <q-btn class="q-mt-lg" @click="clearLog">Clear</q-btn>
  </div>
</div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'DebugLog',

  inject: ['renderer'],

  data() {
    return {
      localEvents: [], // reactivity
      jsonDialog: {
        show: false, // visibility
        data: null, // data to show
      },
    }
  },

  computed: {
    appId() {
      return this.renderer?.a2u?.source?.appId || null;
    },

    moduleId() {
      return this.renderer?.a2u?.moduleId || null;
    },

    /**
     * Computed property that returns an array of events.
     * Each event is an object that contains information about a flow start or finish.
     *
     * @returns {Array} An array of event objects. Each object contains:
     * - processNum: The process number.
     * - type: The type of the event ('flow').
     * - time: The time the event occurred, formatted as 'HH:mm:ss'.
     * - message: The message associated with the event.
     * - diagramName: The name of the diagram associated with the event.
     * - component: An object containing information about the component associated with the event.
     * - incoming: An object containing information about the incoming event.
     * - outgoing: An object containing information about the outgoing event.
     */
    events() {
      return this.localEvents.reduce((res, {type, message, data, time}) => {
        // If the event type is 'flow-start', create a new event object and add it to the array.
        if (['flow-start', 'unhandled', 'warning', 'error'].includes(type)) {
          const node = this.renderer?.a2u?.blocks[data?.component?.id]?.node;

          res.push({
            processNum: data.processNum || null,
            type: type === 'flow-start' ? 'flow' : type,
            time: moment(time).format('HH:mm:ss'),
            message,
            diagramName: this.renderer?.a2u?.diagrams[data?.diagram?.id]?.title || 'unknown',
            diagramId: data?.diagram?.id,
            component: {
              id: data?.component?.id,
              properties: data?.component?.properties ? JSON.stringify(data.component.properties, null, 2) : null,
              type: node?.type || 'unknown',
              title: node?.properties?.title || node?.title || 'unknown',
            },
            incoming: {
              event: data.event || null,
              data: data.data ? JSON.stringify(data.data, null, 2) : null,
            },
            outgoing: {},
          });
        }
        // If the event type is 'flow-finish', find the corresponding 'flow-start' event and add the outgoing information.
        else if (type === 'flow-finish') {
          const idx = res.findIndex(e => e.type === 'flow' && e.processNum === data.processNum);

          if (idx !== -1) {
            res[idx].outgoing = {
              event: data.event,
              data: JSON.stringify(data.data, null, 2),
            }
          }
        }

        return res;
      }, []);
    },
  },

  methods: {
    /**
     * Method to display the JSON dialog with the provided data.
     *
     * @param {Object|string} data - The data to be displayed in the JSON dialog.
     */
    showJsonDialog(data) {
      this.jsonDialog.data = data; // Set the data to be displayed in the JSON dialog.
      this.jsonDialog.show = true; // Show the JSON dialog.
    },

    /**
     * This method is used to update the localEvents data property.
     * It assigns the events from the debugLogger of the renderer's a2u object to localEvents.
     * If the debugLogger or its events do not exist, it assigns an empty array to localEvents.
     */
    updateLocalEvents() {
      this.localEvents = this.renderer?.a2u?.debugLogger?.events || [];
    },

    /**
     * This method is used to clear the debugLogger of the renderer's a2u object and update the localEvents data property.
     * It calls the clear method of the debugLogger if it exists, effectively removing all logged events.
     * After clearing the debugLogger, it calls the updateLocalEvents method to ensure that localEvents reflects the current state of the debugLogger.
     */
    clearLog() {
      this.renderer?.a2u?.debugLogger?.clear();

      this.updateLocalEvents();
    },

    /**
     * This method is used to compute the color of the event based on its type.
     * It checks the type of the event and returns a color accordingly.
     *
     * @param {Object} event - The event object.
     * @returns {string} The color of the event based on its type.
     */
    computeEventColor(event) {
      // If the event type is 'unhandled' or 'error', it returns 'red'.
      if (['unhandled', 'error'].includes(event.type)) {
        return 'red';
      }

      // If the event type is 'warning', it returns 'orange'.
      if (event.type === 'warning') {
        return 'orange';
      }

      // For all other event types, it returns 'primary'.
      return 'primary';
    },

    /**
     * Navigates to the component in the workspace.
     *
     * @param {Object} eventData - The event data containing information about the component.
     * @param {string} eventData.diagramId - The ID of the diagram.
     * @param {string} eventData.component.id - The ID of the component.
     */
    goToComponent(eventData) {
      if (!this.appId || !this.moduleId || !process.env.VUE_APP_A2U_FRONTEND_URL) {
        return;
      }

      const diagramId = eventData?.diagramId;
      const componentId = eventData?.component?.id;

      if (!diagramId || !componentId) {
        return;
      }

      window.open(`${process.env.VUE_APP_A2U_FRONTEND_URL}workspace/${this.appId}/${this.moduleId}/components/${diagramId}?blockId=${componentId}`, '_blank');
    },
  },

  created() {
    // Update localEvents when the component is created.
    this.updateLocalEvents();
  },
}
</script>

<style scoped lang="scss">

</style>
