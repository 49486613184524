<template>
  <div class="q-pl-md">
    <span>- {{ item }}</span>
    <div v-if="nextItem">
      <call-stack-item :item="nextItem" :items="items" :index="index + 1" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CallStackItem',

  props: {
    item: {
      required: true,
      type: String,
    },
    items: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    nextItem() {
      return this.items[this.index + 1] || null;
    },
  },
}
</script>
