<template>
  <div>
    <div v-if="!validationResult.length" class="text-subtitle1">
      No problems found
    </div>

    <q-list>
      <template
        v-for="(item, idx) in validationResult"
        :key="idx"
      >
        <q-separator v-if="idx > 0" class="q-my-md" />
        <q-item class="shadow-1">
          <q-item-section>
            <q-item-label class="text-negative">{{ item.message }}</q-item-label>

            <div class="q-pl-md">
              <div>
                <strong>Go to the component:</strong> <a :href="item.blockLink" target="_blank">
                {{ item.data?.block?.title || item.blockLink }}
              </a>
              </div>
              <div v-if="item.type === 'Argument'">
                <strong>Argument id:</strong> {{ item.data.argumentId }}
              </div>
              <div v-else-if="item.type === 'Constant'">
                <div><strong>Target type:</strong> {{ item.data.type }}</div>
                <div><strong>Current value:</strong> {{ item.data.value }}</div>
              </div>

              <div>
                <strong>Call stack:</strong>
                <call-stack-item :items="item.callStack" :item="item.callStack[0]" />
              </div>
            </div>
          </q-item-section>
        </q-item>
      </template>
    </q-list>
  </div>
</template>

<script>
import CallStackItem from './problems/CallStackItem.vue';

export default {
  name: 'AppProblems',
  components: {CallStackItem},

  // Injected properties
  inject: ['renderer'],

  data() {
    return {};
  },

  computed: {
    /**
     * Computes the validation result by mapping over the schema validation result
     * and extracting error details, including generating block links.
     * @returns {Array} An array of validation error objects with additional blockLink property.
     */
    validationResult() {
      return (this?.renderer?.schemaValidationResult || []).map((item) => {
        return item.errors.map((error) => {
          return {
            ...error,
            blockLink: `${item.diagramLink}?blockId=${error.data?.block?.id}`,
          };
        });
      }).flat();
    },
  },
}
</script>
