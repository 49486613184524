<template>
  <q-dialog ref="dialog" full-height full-width persistent fullscreen no-backdrop-dismiss no-shake maximized>
    <div class="voice-chat-editor-cmp dg-pt-sa relative-position" :style="screenStyle">

      <div class="overlay column">
        <div class="absolute-right">
          <q-btn icon="close" @click="close" size="sm" outline round class="text-white close-btn q-mr-sm"/>
        </div>

        <div class="title">{{title}}</div>
        <q-space/>

        <div class="call-panel" :class="currentStatus">

          <div class="mic">
            <template v-if="['idle', 'talking'].includes(currentStatus)">
            <lottie-player :animation-url="require('./mic-ripple.json')"/>
            <q-btn icon="mic" round size="30px" class="mic-button" @touchend.stop.prevent="stop"  @touchstart.prevent.stop="talk" @mouseup.stop.prevent="stop" @mousedown.prevent.stop="talk"/>
            </template>
            <template v-else >
              <lottie-player :animation-url="require('./waiting.json')"/>
              <q-btn icon="call_end" round size="20px" class="mic-button" @click="close"/>
            </template>

            <div class="status">{{getStatusTitle(currentStatus)}}</div>
          </div>
        </div>
      </div>
    </div>
  </q-dialog>
</template>

<script>

// Import components
import LottiePlayer from "../../Basic/Lottie/LottiePlayer.vue";
import {VoiceChatService} from "../../ChatBot/VoiceChat/VoiceChatService";
import {SoundManager} from "../../../utils/soundManager";

// Create voice chat service
let voiceChatService;

// Export component
export default {
  name: "VoiceChatScreen",
  components: {LottiePlayer},
  props: {
    channel: {},
    title: {},
    botGender: {},
    abChatBotId: {},
    backgroundImage: {},
    renderer: {},
    caller: {}
  },
  data: () => ({
    callStatus: "idle",
  }),
  async created() {
    // Create voice chat service
    voiceChatService = new VoiceChatService(this.sendToServer);

    await voiceChatService.init();

    // Subscribing to the chat channel
    this.renderer.a2u.socketListener.on('ab-chat:audio-answer', this.incomingMessage);
  },

  // Destroy voice chat service
  beforeUnmount() {
    // Destroy voice chat service
    voiceChatService.destroy();

    // Dispose sound
    SoundManager.getInstance().disposeChannel('voiceСhat');

    // Remove listener
    this.renderer.a2u.socketListener.removeListener('ab-chat:audio-answer', this.incomingMessage);
  },

  // Computed properties
  computed: {
    screenStyle() {
      const styles = {};

      // Check if there is a background image
      if (this.backgroundImage) {
        styles.backgroundImage = `url(${this.renderer.a2u.assetPath(this.backgroundImage)})`;
      }

      // Return styles
      return styles;
    },


    /**
     * Checks if the bot is currently writing.
     * @returns {boolean} True if the bot is writing, otherwise false.
     */
    currentStatus() {
      return this.callStatus
    },

  },

  methods: {

    /**
     * Closed
     */
    close() {
      this.$refs.dialog.hide();
      this.caller.processOutgoingLinks(this.caller.context, this.caller.block.id, undefined, 'closed');
    },

    /**
     * Get status title
     * @param status
     */
    getStatusTitle(status) {
      return {
        "idle": "Hold and speak",
        "talking": "Speak now",
        "waiting": "Wait for an answer"
      }[status] || status
    },

    /**
     * Incoming message
     * @param data
     */
    incomingMessage(data) {
      console.log(data);
      if (data.chat_id === this.channel) {
        // Check if there is content
        if (!data.content) {
          this.callStatus = "idle";

          return;
        }

        // Convert base64 mp3 to blob
        const soundAsset = "data:audio/mp3;base64," + data.content;

        // Play audio
        SoundManager.getInstance().playSound('voiceСhat', soundAsset, false,  false, (c, t) => {
          if(c==t) {
            console.log("Sound played!");
            this.callStatus = "idle";
          }
        });
      }
    },

    /**
     * Send to server
     * @param audioBlob
     * @return {Promise<void>}
     */
    async sendToServer(audioBlob) {

      // Convert blob to base64
      const audioBase64 = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(audioBlob);
      });

      // Send to server
      return await this.renderer.app.client.call(this.renderer.a2u.getBackendUrl() + "/ab-chat", "sendMessage", this.abChatBotId, this.channel, {
        type: "voice-chat-message",
        audio: audioBase64,
        botGender: this.botGender
      });
    },

    /**
     * Talk
     */
    async talk() {

      // Check access
      const hasAccess = await voiceChatService.checkMicrophoneAccess()

      // Check access
      if(!hasAccess) return this.caller.processOutgoingLinks(this.caller.context, this.caller.block.id, undefined, 'no-permission');

      console.log("Talk");
      voiceChatService.start();
      this.callStatus = "talking"
    },

    /**
     * Stop talking
     */
    async stop() {
      console.log("Stop");
      voiceChatService.stop();
      this.callStatus = "waiting"
    }
  }
}

</script>

<style lang="scss">

.voice-chat-editor-cmp {
  background-color: black;
  background-position: center center;
  background-size: cover;

  .overlay {
    background: linear-gradient(rgba(1, 1, 1, 0), rgba(0, 0, 0, 1));
    width: 100%;
    height: 100%;
  }


  .waiting {
    .mic-button {
      background-color: #a00;
    }
  }
  .idle {
    .mic-button {
      background-color: #0a0;
    }
  }

  .talking {
    .mic-button {
      background-color: #00a;
    }
  }

  .call-panel {
    padding: 20px;
    margin: 20px;
    color: white;
    display: flex;
    justify-content: center;

    .waiting {
      width: 200px;
      height: 200px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      display: flex;
    }

    .mic {
      width: 200px;
      height: 200px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      display: flex;

      * {
        position: absolute;
        align-items: center;
        justify-content: center;
      }

      .status {
        margin-top: 130px;
      }
    }
  }

  .title {
    font-size: 24px;
    text-align: center;
    padding: 20px;
    color: white;
  }

  .close-btn {
    margin-top: calc(8px + var(--safe-area-top, 0px));
  }
}

</style>
