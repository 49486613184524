import {reactive} from 'vue';
import {nanoid} from 'nanoid';
import {AbStorage} from "a2u-renderer-common/src/utils/abStorage";
import {SchemaLinksIndexer} from 'a2u-renderer-common/src/utils/schemaLinksIndexer';
import {ComponentsManager} from 'a2u-renderer-common/src/utils/componentsManager';
import {SoundManager} from "./soundManager";
import moment from "moment/moment";
import {AbDatabase} from "./abDatabase";
import {Device} from "./device";
import {AnalyticsManager} from "./analyticsManager";
import {AdManager} from "./adManager";
import {DebugLogger} from './debugLogger';
import {ProcessManager} from './processManager';
import {DiagramProcess} from './diagramProcess';
import axios from 'axios';
import {SocketListener} from "../utils/socketListener";

/**
 * Converts a given HTTP or HTTPS URL to a WebSocket URL.
 *
 * This function takes a URL as input and returns a WebSocket URL if the input URL's protocol is HTTP or HTTPS.
 * If the input URL's protocol is not HTTP or HTTPS, it throws an error.
 * If the input URL is null or undefined, it returns null.
 * If an error occurs during the conversion, it logs the error and returns null.
 *
 * @param {string} url - The URL to be converted to a WebSocket URL.
 * @returns {string|null} The converted WebSocket URL, or null if the input URL is null, undefined, or an error occurs during conversion.
 * @throws {Error} Throws an error if the input URL's protocol is not HTTP or HTTPS.
 */
function convertToWebSocketURL(url) {
    if (!url) {
        return null;
    }

    try {
        const parsedUrl = new URL(url);
        let wsScheme;
        if (parsedUrl.protocol === 'http:') {
            wsScheme = 'ws:';
        } else if (parsedUrl.protocol === 'https:') {
            wsScheme = 'wss:';
        } else {
            throw new Error('Unsupported protocol in URL. Only http and https are supported.');
        }

        return `${wsScheme}//${parsedUrl.host}${parsedUrl.pathname}`;
    } catch (error) {
        console.error('Failed to convert URL:', error);
        return null;
    }
}

export class AppParser {

    /**
     * App constructor
     */
    constructor({
                    context,
                    nativeComponents,
                    designerComponentsList,
                    runMode,
                    functions,
                    dbConfig,
                    plugins,
                    models,
                    onInit,
                    onDestroy,
                    platform,
                    moduleId,
                    isUnitTest
                }) {

        // Init variables
        this.context = context;
        this.device = new Device(this, plugins);
        this.dbConfig = dbConfig;
        this.nativeComponents = nativeComponents
        this.designerComponentsList = designerComponentsList;
        this.runMode = runMode;
        this.blocks = {};
        this.diagrams = {};
        this.functions = functions;
        this.models = models || {};
        this.links = {};
        this.dbs = {};
        this.storage = null;
        this.constantStorage = null;
        this.moduleId = parseInt(moduleId);
        this.source = null;
        this.isUnitTest = isUnitTest;
        this.styles = null;
        this.onInit = onInit;
        this.onDestroy = onDestroy;
        this.platform = platform;
        this.currentDiagramComonent = null;
        this.storageContext = null;
        this.restoreForbiddenCounter = 0;
        this.currentSessionId = 0;
        this.processManager = new ProcessManager({app: this});
        this.socketListener = new SocketListener(this)
        this.remoteResourcesMap = reactive({});

        // System purposed storage
        this.systemStorage = new AbStorage({
            storageCreator: (data) => reactive(data),
        })

        // Auth api url
        this.authUrl = false

        // last process number
        this.lastProcessNum = 0;

        // Debug logger
        this.debugLogger = new DebugLogger();

        // Error logger
        this.errorLogger = this.device.getPluginInstance('ErrorLogger');

        // Set first visit flag
        this.firstVisit = false;
    }

    /**
     * Set app source
     */
    setSource(source) {
        this.source = source;
        return this;
    }

    /**
     * Get app code version
     * @return {*}
     */
    getCodeVersion() {
        return this.source.codeVersion
    }

    /**
     * Get app version name
     * @return {*}
     */
    getVersionName() {
        return this.source.versionName
    }

    /**
     * Get app schema version
     * @return {number|*}
     */
    getSchemaVersion() {
        return this.source.schemaVersion
    }


    /**
     * Get app module id
     * @return {number|*}
     */
    getModuleId() {
        return this.moduleId
    }

    /**
     * Parse app
     */
    async parseApp() {

        // Assets context
        try {
            this.storageContext = require.context('@/assets/storage', true, /\.*$/);
        } catch (ex) {
            console.log("No assets context found")
        }

        // Debug operations on source
        await this.setupModuleEndpoints();

        // Unpack all diagrams
        this.diagrams = Object.fromEntries(this.source.diagrams.map(d => {
            return [d.id, d];
        }))

        // Index links
        const { links, blocks } = SchemaLinksIndexer.indexing(this.source.diagrams);
        this.links = links;
        this.blocks = blocks;

        // Init components manager
        this.componentsManager = new ComponentsManager({
            componentsList: this.designerComponentsList,
            links: this.links,
            renderer: this,
        });

        // Init auth system
        await this.initAuthSystem();

        // Add root diagram
        this.blocks['root'] = {
            node: {
                id: "root",
                type: "DiagramComponent",
                properties: {
                    diagramComponentId: this.source?.startDiagramId
                },
            }
        };

        // Init database
        await this.initDatabase(this.runMode);

        // Init storage
        await this.initStorage();

        // Init sound manager
        SoundManager.getInstance().init(this.storage);

        // Init analytics manager
        this.analytics = new AnalyticsManager(this);

        // Init ads manager
        this.adManager = new AdManager(this)
        await this.adManager.init(this.source.ads);

        // Init app
        await this.onInit?.(this);

        // Init socket
        await this.socketListener.init();

        // All ok
        return true;
    }

    /**
     * State changed
     */
    stateChanged() {
        SoundManager.getInstance().updateState()
    }

    /**
     * Dispose app
     */
    dispose() {

        // Dispose sound manager
        SoundManager.getInstance().dispose()

        // Dispose app
        this.onDestroy?.(this);
    }

    /**
     * Perform debug operations
     * @return {Promise<void>}
     */
    async setupModuleEndpoints() {
        // Patch module endpoints if .env file contains them
        for(const mod of Object.values(this.source?.modules || {})) {
            // Get from config
            mod.endpointUrl = mod.endpoint?.[this.runMode.toLowerCase()];

            // Override from env
            const key = `VUE_APP_MODULE_${mod.id}_${this.runMode.toUpperCase()}_ENDPOINT`;
            if(process.env[key]) mod.endpointUrl = process.env[key];

            // Define socket url
            if (mod?.endpoint?.hasWebSocket === 1 && mod.type === 'server') {
                mod.socketUrl = convertToWebSocketURL(mod.endpointUrl);
            }

            // Define cdn url
            if (mod.type === 'server' && !process.env[key]) {
                mod.endpointCdnUrl = mod.endpoint?.[`cdn_${this.runMode.toLowerCase()}`] || null;
            }
        }
    }

    /**
     * Init storage
     */
    async initStorage() {

        // Create storage
        this.constantStorage = new AbStorage({storage: this.source?.storage?.data?.['constants'] || {}});
        console.log("ssss", this.source?.storage?.data?.['constants'], this.constantStorage)

        // get app storage
        const appStorageData = this.source?.storage?.data?.['app-storage'];

        // Create storage
        this.storage = this.context.app.client.config || new AbStorage({
            storageCreator: (data) => reactive(data),
        });

        // Set storage
        this.storage.setInternalStorage(appStorageData || {});

        // Set first session date
        if(this.storage.get("settings.statistics.firstSessionDate", 0) === 0) {
            this.storage.set("settings.statistics.firstSessionDate", moment().unix())
            this.storage.set("settings.statistics.randomSeed", Math.ceil(1000000*Math.random()))

            this.firstVisit = true;
        }

        // Set current platform
        this.storage.set("settings.platform.os", this.getPlatform());

        // Set application version
        this.storage.set("settings.application.version", `${this.getVersionName() || '1.0.0'} (${this.getCodeVersion() || '1'})`);

        // Set keyboard state
        this.storage.set("settings.application.isKeyboardOpen", false);
    }

    /**
     * Init auth system
     * @return {Promise<void>}
     */
    async initAuthSystem() {

        // Find backend database with users table
        const db = Object.values(this.source?.databases || {}).
        find(d => Object.values(d.tables || {}).
        find(t => t?.name === 'users'));

        // Get module info by id
        const moduleInfo = db?.module_id ? Object.values(this.source?.modules || {})?.find(m => m.id === db.module_id) : false;

        // Set auth url
        this.authUrl = moduleInfo?.endpointUrl

        // Set auth url to system
        if(this.authUrl) await this.context.app.client.setAuthUrl(this.authUrl)
    }

    /**
     * Recursively calls a handler function for a diagram and its parent diagrams.
     * This method attempts to call a specified handler on the given diagram. If the diagram has a parent,
     * it recursively calls the same handler on the parent diagram. Errors during the handler call are caught
     * and not propagated, effectively muting them.
     *
     * @param {Object} diagram - The diagram object on which to call the handler.
     * @param {string} group - The group name of the handler to call.
     * @param {string} event - The event name to pass to the handler.
     * @param {Object} data - The data object to pass to the handler.
     */
    _callDiagramHandler(diagram, group, event, data) {
        try {
            diagram.callHandler(group, event, data);
        } catch (e) {
            // mute errors
        }

        if (diagram?.parentDiagram) {
            this._callDiagramHandler(diagram.parentDiagram, group, event, data);
        }
    }


    /**
     * Init database
     */
    async initDatabase() {

        // Init local database
        let localDb = new AbDatabase(this, this.context.app, !this.isUnitTest && (this.runMode === 'debug' || this.source?.modules[this.moduleId]?.persistent_storage), this.models)

        // Get database config
        for (const [db, config] of Object.entries(this.source?.databases)) {

            // Store database
            this.dbs[db] = localDb // || new AbDatabase(this, this.context.app, this.source?.modules[this.moduleId]?.persistent_storage || false)

            // Init database
            await this.dbs[db].init(Object.assign(this.dbConfig || {}, config))
        }
    }

    /**
     * Get device
     * @return {*}
     */
    getDevice() {
        return this.device
    }

    /**
     * Get platform
     * @return {*}
     */
    getPlatform() {
        return this.platform
    }

    /**
     * This method is used to get a specific database configuration from the source databases based on the provided name.
     * If no name is provided, it returns the configuration of the first database in the source databases.
     * If the provided name does not match any database in the source databases or if the module ID of the matched database does not match the module ID of the current instance, it returns undefined.
     * Otherwise, it returns the database instance associated with the ID of the matched database.
     *
     * @param {string} name - The name of the database to get. If not provided, the method returns the configuration of the first database in the source databases.
     * @returns {Object|boolean} The database instance associated with the ID of the matched database or false if no database is matched.
     */
    getDb(name) {
        const dbs = Object.values(this.source?.databases)
            .filter((db) => db.module_id === this.moduleId);

        const dbCnf = name ? dbs.find(d => d.name === name) : dbs[0] ?? undefined;

        return dbCnf ? this.dbs[dbCnf.id] : false
    }

    /**
     * Get panel styles by id
     * @param id
     */
    getPanelStylesById(id) {
        return this.source?.styles?.panelStyles?.find(ps => ps.properties?.id === id)?.properties || []
    }

    /**
     * Apply styles to page
     * @return {string}
     */
    getHtmlStyles() {

        // Get styles from current theme style
        this.stylesSource = this.source?.styles;

        // Styles string
        let tsStr = "";

        // Add colors
        for (const [key, value] of Object.entries(this.stylesSource?.colors || {})) {
            tsStr += `.dg-background-${key} {background-color:${value.background};stroke:${value.background}; }\n`
            tsStr += `.dg-foreground-${key} {color:${value.foreground};stoke:${value.foreground};}\n`
            tsStr += `:root { --foreground-color-${key}: ${value.foreground}; }\n`
            tsStr += `:root { --background-color-${key}: ${value.background}; }\n`
        }

        // Add text styles
        for (const [key, value] of Object.entries(this.stylesSource?.textStyles || {})) {
            tsStr += `.dg-text-${key} {`
            if (value.fontSize) tsStr += `font-size:${value.fontSize}em;`
            if (value.fontWeight) tsStr += `font-weight:${value.fontWeight};`
            if (value.fontFamily) tsStr += `font-family:"${value.fontFamily}";`

            tsStr += `}\n`
        }

        // Return styles
        return tsStr;
    }


    /**
     * Get component by type
     * @param cmpType
     */
    getComponentByType(cmpType) {
        return this.componentsManager.getComponentByType(cmpType);
    }

    /**
     * Show error
     * @param text
     */
    showError(text) {
        alert(text)
    }

    /**
     * Set current diagram component
     * @param diagram
     */
    setCurrentDiagram(diagram) {
        this.currentDiagramComonent = diagram;
    }
    /**
     * Get style color
     * @param name
     * @param type
     */
    getColor(name, type) {
        return this.stylesSource?.colors?.[name]?.[type]?.toUpperCase()
    }

    /**
     * Process outgoing links
     * @param owner
     * @param context
     * @param blockId
     * @param event
     * @param data
     */
    processOutgoingLinks(owner, context, blockId, data, event) {
        this.componentsManager.processOutgoingLinks(owner, context, blockId, data, event, (lnk) => {
            if(!lnk?.excludeFromAnalytics) {
                this.analytics.logEvent("link", {id: lnk.linkId, diagram_id: lnk.diagramId});
            }
        });
    }

    /**
     * This method is used to create an instance of a processor for a given component.
     * It first retrieves the component block from the blocks dictionary using the provided component ID.
     * If the component block does not exist, it logs an error message and returns undefined.
     * It then retrieves the component definition by its type.
     * If the component definition does not exist or it does not have a processor, it logs an error message and returns undefined.
     * It increments the last process number and if the application is not in release mode, it logs the start of the flow.
     * This includes the process number, the ID of the diagram, the ID and properties of the component, the event that triggered the flow, and the data associated with the event.
     * Finally, it creates a new instance of the component's processor and returns it.
     *
     * @param {Object} owner - The owner of the component.
     * @param {Object} context - The context in which the component is being processed.
     * @param {string} cmpId - The ID of the component.
     * @param {Object} data - The data associated with the event that triggered the flow.
     * @param {string} event
     * @returns {Object} An instance of the component's processor or undefined if the component or its processor does not exist.
     */
    makeProcessorInstanceById(owner, context, cmpId, data, event) {
        // Load component
        const block = this.blocks[cmpId];
        const cmp = block?.node;

        if(!cmp) {
            console.error(`Component ${cmpId} not found`);
            return undefined;
        }

        // Get processor by type
        const component = this.getComponentByType(cmp.type);

        // Check if component has processor
        if (!component || !component.processor) {
            console.error(`Component ${cmp.type} has no processor`);
            return undefined;
        }

        // Increment the process number.
        this.lastProcessNum++;

        // If the application is not in release mode, log the start of the flow.
        // This is useful for debugging and understanding the flow of the application.
        // The log includes the following information:
        // - The process number.
        // - The ID of the diagram.
        // - The ID and properties of the component.
        // - The event that triggered the flow.
        // - The data associated with the event.

        // TODO, code below ruins reactivity logic (recursively walks through tree and make everything reactive)  and get wrong variable values.
        if (this.runMode !== 'release') {
            // Clone the properties of the component to avoid modifying the original properties.
            const componentProperties = cmp.properties ? JSON.parse(JSON.stringify(cmp.properties)) : {};

            // Patch the properties of the component.
            this.patchComponentProperties(owner, componentProperties);

            this.debugLogger.log({
                type: 'flow-start',
                message: 'Flow started',
                data: JSON.parse(JSON.stringify({
                    processNum: this.lastProcessNum,
                    diagram: { id: block.diagramId },
                    component: { id: cmp.id, properties: this.patchComponentProperties(owner, componentProperties) },
                    event,
                    data,
                })),
            })
        }

        return new component.processor(owner, context, cmp, this.lastProcessNum);
    }

    /**
     * Run component
     * @param owner
     * @param context
     * @param cmpId
     * @param event
     * @param data
     * @param linkId
     */
    run(owner, context, cmpId, event, data, linkId) {
        const processor = this.makeProcessorInstanceById(owner, context, cmpId, data, event);

        // Check if component has processor
        if (!processor) {
            return;
        }

        // Process event
        processor.processEvent(event, data, linkId);
    }

    /**
     * This method is used to patch the properties of a component.
     * It traverses the properties tree of the component and updates the current value of each property that has a 'variable' value type.
     * The current value of a property with 'variable' value type is obtained by calling the getValue method of the provided context with the property as an argument.
     * If a property is an object, this method is called recursively on that property.
     *
     * @param {Object} context - The context from which to get the current value of a property.
     * @param {Object} properties - The properties tree of the component to patch.
     * @returns {Object} The patched properties tree.
     */
    patchComponentProperties(context, properties) {
        const getValue = context.getValue || context?.currentDiagramComonent?.getValue;

        const clonedProperties = JSON.parse(JSON.stringify(properties || {}));

        if (typeof getValue !== 'function') {
            return clonedProperties;
        }

        const patchDeep = (tree) => {
            if (tree?.valueType === 'variable') {
                tree.currentValue = getValue(tree);
            }

            if (tree && typeof tree === 'object') {
                for (const prop of Object.values(tree)) {
                    if (typeof prop === 'object') {
                        patchDeep(prop);
                    }
                }
            }
        };

        patchDeep(clonedProperties);

        return clonedProperties;
    }

    /**
     * Get var meta by id
     * @param id
     * @return {*}
     */
    getVarMeta(id) {
        return this.source?.storage?.meta?.[id];
    }

    /**
     * Get current session id
     * @return {number}
     */
    getCurrentSessionId() {
        return this.currentSessionId
    }

    /**
     * App restored from background
     */
    restore() {

        // Update socket listener
        this.socketListener.update();

        // Check if restore allowed
        if(this.restoreForbiddenCounter) return;

        // Increment session id
        this.currentSessionId++

        // Call restore event
        if(this.currentDiagramComonent) this.currentDiagramComonent?.restore();

        // Resume sounds
        SoundManager.getInstance().resume();

        // Send events to a2u
        this._callDiagramHandler(this.currentDiagramComonent, 'app', 'restore', {});
    }

    /**
     * App paused
     */
    pause() {

        // Check if restore allowed
        if(this.restoreForbiddenCounter) return;

        // Call restore event
        if(this.currentDiagramComonent) this.currentDiagramComonent?.pause();

        // Pause sounds
        SoundManager.getInstance().pauseAll();
    }

    /**
     * Allow calling restore event
     * @param mode
     * @param timeout
     */
    allowRestore(mode, timeout = 1000) {
        // Forbid restore
        if(!mode) {
            // Disallow restore
            this.restoreForbiddenCounter++

            // Log
            console.log("Restore forbidden", this.restoreForbiddenCounter)
        } else {
            // Allow restore after timeout
            setTimeout(() => {
                this.restoreForbiddenCounter--
                console.log("Restore allowed", this.restoreForbiddenCounter)
            }, timeout)
        }
    }

    /**
     * Hide app loader
     */
    hideLoader() {
        window.hideLoader?.();
    }

    /**
     * Retrieves the URL of the CDN server.
     *
     * The method first checks if the 'endpoint' property exists in the 'cdnServer' configuration of the source.
     * If the 'endpoint' property is a string, it returns the string.
     * Otherwise, it checks the 'runMode' of the application.
     * If the 'runMode' is 'debug', it returns the 'stage' endpoint.
     * Otherwise, it returns the endpoint corresponding to the 'runMode'.
     * If no specific endpoint is found for the 'runMode', it returns the 'endpoint' object itself.
     *
     * @returns {string|Object} The URL of the CDN server or the 'endpoint' object if no specific URL is found.
     */
    getCdnUrl() {

        // Get endpoint from schema
        let endpoint = this.source?.cdnServer?.endpoint || {};
        const cdnModuleId = this.source?.cdnServer?.moduleId

        // Override from env if test flag is enabled
        const key = `VUE_APP_MODULE_${cdnModuleId}_${this.runMode.toUpperCase()}_ENDPOINT`;

        if(process.env[key]) {
            endpoint = `${process.env[key].replace(/\/$/, '')}/storage/download`;
        }

        // Return string if string
        if (typeof endpoint === 'string') {
            return endpoint;
        }

        const envEndpoint = endpoint[this.runMode === 'debug' ? 'stage' : this.runMode];

        // Return endpoint according to run mode
        return envEndpoint ? `${envEndpoint}/storage/download` : endpoint;
    }

    /**
     * Retrieves the URL of the backend server.
     * If id not set - get first module with url and type server
     * @param id
     */
    getBackendUrl(id) {

        // Get id from schema
        if(!id) id = this.getBackendId();

        // Get endpoint from schema
        return this.source?.modules[id]?.endpointUrl;
    }

    /**
     * Get backend id
     */
    getBackendId() {
        // Run through all modules and find backend with endpoint url
        for(const mod of Object.values(this.source?.modules || {})) {
            if(mod.type === 'server' && mod.endpointUrl) {
                return mod.id;
            }
        }
    }

    /**
     * Asynchronously retrieves the path of a remote asset.
     * This method first checks if the path is already cached in the `remoteResourcesMap`. If it is, it returns the cached path.
     * If not, it retrieves the CDN URL and checks if the CDN server is found. If the CDN server is not found, it logs an error and returns `undefined`.
     * If the application is in debug mode, it constructs and returns the path using the CDN URL and the provided path.
     * Otherwise, it retrieves the CDN manager from the device plugins and checks if the CDN manager is found. If the CDN manager is not found, it logs an error and returns `undefined`.
     * If the path is not already cached, it sets the path to `false` in the `remoteResourcesMap` and then loads the path using the CDN manager.
     * Finally, it caches the loaded path in the `remoteResourcesMap` and returns it.
     *
     * @param {string} path - The path of the remote asset to retrieve.
     * @returns {Promise<string|undefined>} The path of the remote asset, or `undefined` if the CDN server or CDN manager is not found.
     */
    async _remoteAssetPathAsync(path) {
        if (this.remoteResourcesMap[path] !== false) {
            return this.remoteResourcesMap[path];
        }

        // Get CDN URL
        const cdnUrl = this.getCdnUrl();

        // Check if CDN server is found
        if (!cdnUrl) {
            console.error("CDN server not found");

            return undefined;
        }

        // Return the path of the remote asset
        if (this.runMode === 'debug') {
            return `${cdnUrl}/storage/${path}`;
        }

        // Get the CDN manager from the device plugins
        const cdnManager = this.getDevice()?.getPluginInstance?.("CdnManager");

        // Check if the CDN manager is found
        if (!cdnManager) {
            console.error("CDN manager not found");

            return undefined;
        }

        if (!this.remoteResourcesMap[path]) {
            this.remoteResourcesMap[path] = false;
        }

        this.remoteResourcesMap[path] = await cdnManager.load(path);

        return this.remoteResourcesMap[path];
    }

    /**
     * Retrieves the path of a remote asset.
     * This method calls the asynchronous `_remoteAssetPathAsync` method to load the path of the remote asset.
     * It then returns the cached path from the `remoteResourcesMap`.
     *
     * @param {string} path - The path of the remote asset to retrieve.
     * @returns {string|undefined} The path of the remote asset, or `undefined` if the path is not found.
     */
    remoteAssetPath(path) {
        if (!(path in this.remoteResourcesMap)) {
            this.remoteResourcesMap[path] = false;
        }

        this._remoteAssetPathAsync(path);

        return this.remoteResourcesMap[path];
    }

    /**
     * Asynchronously retrieves the path of an asset stored in IndexedDB.
     * This method first constructs a key using the provided ID. It then attempts to get the `IndexedDbFilesManager` plugin from the device.
     * If the plugin is not found, it logs an error and returns the cached path from `remoteResourcesMap`.
     * If the path is not already cached, it retrieves the file as a blob from IndexedDB, creates an object URL for the blob, and caches the URL in `remoteResourcesMap`.
     * Finally, it returns the cached URL.
     *
     * @param {number} id - The ID of the asset to retrieve.
     * @returns {Promise<string|null>} The URL of the asset, or null if an error occurs.
     */
    async _indexedDbAssetPathAsync(id) {
        const key = `idb:${id}`;

        try {
            const fileManager = this.getDevice().getPlugin('IndexedDbFilesManager');

            if (!fileManager) {
                console.error('IndexedDbFilesManager plugin not found');

                return this.remoteResourcesMap[key];
            }

            if (!this.remoteResourcesMap[key]) {
                const blob = await fileManager.getFile(id);

                this.remoteResourcesMap[key] = URL.createObjectURL(blob);

                return this.remoteResourcesMap[key];
            }

            return this.remoteResourcesMap[key];
        } catch (e) {
            console.error('Failed to get file from IndexedDB:', e);

            return null;
        }
    }

    /**
     * Retrieves the path of an asset stored in IndexedDB.
     * This method calls the asynchronous `_indexedDbAssetPathAsync` method to load the path of the asset.
     * It then returns the cached path from the `remoteResourcesMap`.
     *
     * @param {number} id - The ID of the asset to retrieve.
     * @returns {string|undefined} The URL of the asset, or `undefined` if the path is not found.
     */
    indexedDbAssetPath(id) {
        const key = `idb:${id}`;

        this._indexedDbAssetPathAsync(id);

        return this.remoteResourcesMap[key];
    }

    /**
     * Retrieves the storage endpoint URL for a given module.
     *
     * This method checks if the module has a specific CDN endpoint URL.
     * If it does, it returns that URL. Otherwise, it returns the module's
     * endpoint URL or a default URL from the environment variables.
     *
     * @param {number} moduleId - The ID of the module to get the storage endpoint for.
     * @returns {string} The storage endpoint URL for the module.
     */
    _getModuleStorageEndpoint(moduleId) {
        const module = this.source?.modules?.[moduleId] || {};

        if (module?.endpointCdnUrl) {
            return `${module.endpointCdnUrl}/`;
        }

        return `${module?.endpointUrl || process.env.VUE_APP_A2U_URL}/storage/download/`;
    }


    /**
     * Get asset path
     * @param url
     * @param storagePath
     */
    assetPath(url, storagePath = process.env.VUE_APP_STORAGE_PATH) {

        // No url
        if(!url) return false

        // Add module path
        if(typeof url === 'object' && url?.source_url) {
            if(url?.storage_module_id || url?.moduleId) {
                // Get module endpoint
                return this.assetPath(url?.source_url, this._getModuleStorageEndpoint(url?.storage_module_id || url.moduleId));
            } else {
                url = url.source_url
            }
        }

        // Check if url is string
        if(typeof url !== 'string') {
            console.error("Invalid image", url)
            return false
        }

        // Parse url
        const [source, path] = url?.split?.(":") || [];

        // Construct path according to source
        switch (source) {
            case "storage":
                if(storagePath.indexOf("http") === 0) {
                    // Remote resource
                    return storagePath + path
                }

                // Local resource
                return this.storageContext ? this.storageContext("./"+path) : null
            case "remote":
                /*if (storagePath.indexOf("http") === 0) {
                    return storagePath + path;
                }*/

                return this.remoteAssetPath(path);
            case "blob":
            case "http":
            case "https":
            case "capacitor":
                return url;
            case 'idb':
                return this.indexedDbAssetPath(parseInt(path));
            default:
                return storagePath + url
        }
    }

    /**
     * Asynchronously retrieves the path of an asset.
     * This method constructs the asset path based on the provided URL and storage path.
     * It supports various URL schemes including storage, remote, blob, http, https, capacitor, and idb.
     * If the URL is an object with a source URL, it recursively calls itself to get the full path.
     * If the URL is a string, it parses the URL and constructs the path based on the source type.
     *
     * @param {string|Object} url - The URL or object containing the source URL of the asset.
     * @param {string} [storagePath=process.env.VUE_APP_STORAGE_PATH] - The base path for storage assets.
     * @returns {Promise<string|boolean|null>} The constructed asset path, or false if the URL is invalid, or null if the local resource is not found.
     */
    async assetPathAsync(url, storagePath = process.env.VUE_APP_STORAGE_PATH) {
        // No url
        if(!url) return false

        // Add module path
        if(typeof url === 'object' && url?.source_url) {
            if(url?.storage_module_id || url?.moduleId) {
                // Get module endpoint
                return await this.assetPathAsync(url?.source_url, this._getModuleStorageEndpoint(url.storage_module_id || url.moduleId));
            } else {
                url = url.source_url
            }
        }

        // Check if url is string
        if(typeof url !== 'string') {
            console.error("Invalid image", url)
            return false
        }

        // Parse url
        const [source, path] = url?.split?.(":") || [];

        // Construct path according to source
        switch (source) {
            case "storage":
                if(storagePath.indexOf("http") === 0) {
                    // Remote resource
                    return storagePath + path
                }

                // Local resource
                return this.storageContext ? this.storageContext("./"+path) : null
            case "remote":
                if (storagePath.indexOf("http") === 0) {
                    return storagePath + path;
                }

                return await this._remoteAssetPathAsync(path);
            case "blob":
            case "http":
            case "https":
            case "capacitor":
                return url;
            case 'idb':
                return await this._indexedDbAssetPathAsync(parseInt(path));
            default:
                return storagePath + url
        }
    }

    /**
     * Get asset base64
     * @param url
     * @return {Promise<void>}
     */
    async assetBase64(url) {

        // Get path
        const imagePath = await this.assetPathAsync(url);

        if(!imagePath) throw "No image path for " + url + " found"

        // Check if path already data
        if(typeof imagePath === 'object') return btoa(JSON.stringify(imagePath))

        try {
            // Fetch the image as a blob
            let response = await axios.get(imagePath, {
                responseType: 'blob'
            });

            // Wait for data
            const data = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result.split(",")[1]);
                }
                reader.onerror = reject;
                reader.readAsDataURL(response.data);
            });

            // Convert blob to base64
            return data
        } catch (error) {
            console.error("Error encoding image:", error);
        }
    }

    /**
     * Call function
     * @param moduleId
     * @param packageName
     * @param funcName
     * @param params
     * @return {Promise<void>}
     */
    async callFunction(packageName, funcName, params, moduleId) {

        // Get function id by name
        const func = Object.values(this.source?.functions).find(f => f.name === funcName && f.package === packageName && (!moduleId || f.module_id===moduleId) );
        if(!func) throw new Error(`Function ${packageName}.${funcName} not found`)

        // Get module by id
        const module = this.source?.modules[func?.module_id];
        if(!module) throw new Error(`Module ${moduleId} not found`)

        // Call
        return this.context.app.client.call(module.endpointUrl + '/function', 'call', func.id, params)
    }

    /**
     * This method is used to get the build target of the current module.
     * It checks if the 'type' property exists in the module's configuration in the source.
     * If it exists, it returns the value of the 'type' property.
     * If it doesn't exist, it defaults to 'web'.
     *
     * @returns {string} The build target of the current module or 'web' if not specified.
     */
    getBuildTarget() {
        return this.source?.modules[this.moduleId]?.type || 'web';
    }

    /**
     * This method is used to create a new instance of the DiagramProcess class.
     * It takes an object as an argument which contains two properties: owner and diagramStorage.
     * The owner property is the owner of the diagram.
     * The diagramStorage property is the storage object for the diagram.
     *
     * The method returns a new instance of the DiagramProcess class which is initialized with an object containing the following properties:
     * - storageMeta: The meta information of the storage from the source.
     * - dataContext: An object that contains the data context of the owner and the diagram storage.
     * - parentDiagram: The current diagram component of the AppParser instance.
     * - renderer: The renderer of the owner.
     *
     * @param {Object} options - The options for creating the DiagramProcess instance.
     * @param {Object} options.owner - The owner of the diagram.
     * @param {Object} options.diagramStorage - The storage object for the diagram.
     * @returns {DiagramProcess} A new instance of the DiagramProcess class.
     */
    createProcessDiagram({ owner, diagramStorage }) {
        // Create process diagram
        return new DiagramProcess({
            storageMeta: this.source?.storage?.meta,
            dataContext: {
                ...(owner?.dataContext || {}),
                diagram: diagramStorage,
            },
            parentDiagram: this.currentDiagramComonent,
            renderer: owner?.renderer,
        });
    }

    /**
     * This method is used to run a specific diagram in the application.
     * It first retrieves the diagram object from the diagrams dictionary using the provided diagram ID.
     * It then finds the 'CustomEvent' component in the diagram's source children that has a 'name' property equal to 'start'.
     * If the 'CustomEvent' component does not exist and the event name is not 'restore' or 'pause', it shows an error message and returns.
     * Finally, it calls the processOutgoingLinks method with the current instance as the owner, the current context, the ID of the 'CustomEvent' component, and the provided data.
     *
     * @param {Object} owner - The owner of the diagram.
     * @param {string} diagramId - The ID of the diagram to run.
     * @param {Object} data - The data to pass to the 'CustomEvent' component.
     * @param {Object} diagramStorage - The storage object for the diagram.
     */
    runDiagramProcess(owner, diagramId, data, diagramStorage) {
        const eventName = 'start';

        const diagramObj = this.diagrams[diagramId];

        // Find CustomEvent component`
        const eventBlock = diagramObj?.source?.children.find(c => c.type === 'CustomEvent' && c.properties?.name === eventName);

        // Check for start event
        if (!eventBlock) {
            if(!['restore', 'pause'].includes(eventName)) {
                this.showError(`Event ${eventName} not found in diagram ${diagramObj?.title}`)
            }

            return;
        }

        // Create process diagram
        const diagram = this.createProcessDiagram({ owner, diagramStorage });

        // Run component
        this.processOutgoingLinks(owner, diagram, eventBlock?.id, data || {});
    }

    /**
     * This method is used to restore a specific event of a component.
     * It first creates an instance of the component's processor by calling the makeProcessorInstanceById method with the current instance as the owner, the current context, the provided component ID, and the provided data.
     * If the processor instance does not exist, it returns.
     * Finally, it calls the restoreEvent method of the processor instance with the provided event and data.
     *
     * @param {Object} owner - The owner of the diagram.
     * @param {string} cmpId - The ID of the component whose event is to be restored.
     * @param {string} event - The name of the event to restore.
     * @param {Object} data - The data associated with the event.
     * @param {Object} diagramStorage - The storage object for the diagram.
     */
    restoreEvent(owner, cmpId, event, data, diagramStorage) {
        // Create process diagram
        const diagram = this.createProcessDiagram({ owner, diagramStorage });

        const processor = this.makeProcessorInstanceById(owner, diagram, cmpId, data, event);

        if (!processor) {
            return;
        }

        processor.restoreEvent(event, data);
    }

    /**
     * Show log message
     * @param params
     */
    log(...params) {
        console.log(...params)
    }

    /**
     * Saves an file locally.
     *
     * This method first fetches the file from the provided URI as a blob using axios.
     * If the blob is not fetched successfully, it throws an error.
     * If the platform of the application is 'web', it tries to get the 'IndexedDbFilesManager' plugin from the device plugins.
     * If the plugin is not found, it throws an error.
     * It then saves the blob in IndexedDB using the 'saveFile' method of the plugin and gets the ID of the saved file.
     * It returns a string that includes the ID of the saved file.
     * If the platform of the application is not 'web', it tries to get the 'FileSystem' plugin from the device plugins.
     * If the plugin is not found, it throws an error.
     * It then extracts the extension of the file from the URI.
     * It reads the blob as a data URL using a FileReader.
     * When the reading is finished, it splits the data URL to get the base64 data of the file.
     * It then writes the base64 data to a file in the file system using the 'writeFile' method of the FileSystem plugin and a generated filename that includes a random ID and the extension of the file.
     * It converts the file source of the written file to a URL using the 'convertFileSrc' method of the FileSystem plugin.
     * Finally, it returns the URL of the written file.
     *
     * @async
     * @param {string} uri - The URI of the file to save.
     * @returns {Promise<string>} A promise that resolves to a string that includes the ID of the saved file if the platform is 'web', or the URL of the saved file if the platform is not 'web'.
     * @throws {Error} Throws an error if the file is not fetched successfully, or if the 'IndexedDbFilesManager' or 'FileSystem' plugin is not found.
     */
    async saveLocally(uri) {
        let blob;

        // Fetch file as blob
        if (this.getPlatform() === 'ios' && uri.startsWith('capacitor://')) {
            const response = await fetch(uri);
            blob = await response.blob();
        } else {
            const response = await axios.get(uri, { responseType: 'blob' });
            blob = response?.data;
        }

        if (!blob) {
            throw new Error('Failed to save file locally');
        }

        // If the platform is 'web', save the file in IndexedDB
        if (this.getPlatform() === 'web') {
            const fileManager = this.getDevice().getPlugin('IndexedDbFilesManager');

            if (!fileManager) {
                throw 'IndexedDbFilesManager plugin not found';
            }

            const fileId = await fileManager.saveFile(blob);

            return `idb:${fileId}`;
        }

        // If the platform is not 'web', save the file in the file system
        const fileSystem = this.getDevice().getPlugin('FileSystem');

        if (!fileSystem) {
            throw 'FileSystem plugin not found';
        }

        const [,ext] = uri.match(/\.([a-zA-Z0-9]+)$/);

        return await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = async function () {
                const base64data = reader.result.split(',')[1];

                const {uri} = await fileSystem.writeFile(`${nanoid(24)}.${ext}`, base64data, false);

                resolve(fileSystem.convertFileSrc(uri));
            };
            reader.readAsDataURL(blob);
        });
    }

    /**
     * Asynchronously calls a tool on the backend using the application's authentication URL.
     * This method is designed to facilitate communication with backend tools by sending a payload
     * and optionally synchronizing the call. It throws an error if the authentication URL is not set,
     * ensuring that the method is only used in contexts where the application is properly configured
     * to communicate with its backend.
     *
     * @param {string} tool - The identifier of the tool to be called on the backend.
     * @param {Object} payload - The data to be sent to the tool. This object contains the parameters
     *                           and values expected by the tool.
     * @param {Object} [options={}] - Additional options for the tool call.
     * @param {boolean} [options.sync] - Determines whether the tool should be called synchronously.
     * @param {boolean} [options.saveFileLocally] - Determines whether the tool should save the file locally.
     * @returns {Promise<Object>} A promise that resolves with the response from the backend tool.
     * @throws {Error} Throws an error if the authentication URL is not found in the application's context.
     */
    async callTool(tool, payload, options = {}) {
        if (!this.authUrl) {
            throw 'Auth URL not found';
        }

        try {
            const {data} = await this.context.app.client.getAxios().post(`${this.authUrl}/tools/call`, [tool, payload, options], {
                timeout: 3600000,
            });

            return data;
        } catch (e) {
            console.log("Request exception ", e)
            throw e?.response?.data?.exception || e;
        }
    }
}
