export class SocketListener {

    /**
     * Socket listener constructor
     * @param app
     */
    constructor(app) {

        // Store app
        this.app = app

        // Listeners
        this.listeners = {}
    }

    /**
     * Setup events
     */
    async init() {
        // Check if the user is authenticated
        if (!this.app.context.app.auth().getUserId()) {
            return;
        }

        // Find the first server module in the schema that has a WebSocket endpoint.
        const socketModule = Object.values(this.app.source.modules).find((m) => !!m.socketUrl);

        // If no such module is found, return.
        if (!socketModule) {
            return null;
        }

        // Initialize socket connection with the converted WebSocket URL.
        await this.app.context?.app?.client?.setSocketUrl(socketModule.socketUrl);

        // Check if the socket connection exists.
        if (!this.app.context?.app?.client?.getSocket()) {
            return;
        }

        // Set up a listener for the 'a2u-channel' event on the socket connection.
        this.app.context.app.client.getSocket()?.on('a2u-channel', (data) => {
            // Add the incoming data to the incoming data object.
            this.addToIncomingData(data.channel, data?.data);

            // Acknowledge the receipt of the data.
            this.app.context.app.client.getSocket()?.emit('user-socket-updates:ack', {
                id: data.id,
            });
        });

        // Listen for db sync events and call sync method
        this.app.context.app.client.getSocket()?.on('db-sync', (data) => {
            this.app.getDb()?.sync(data)
        });

        // Initial update the data
        await this.update();
    }

    /**
     * Add incoming data
     * @param channel
     * @param data
     */
    addToIncomingData(channel, data) {
        console.log('SocketListener.addToIncomingData', channel, data);
        // Send data to listeners
        if (this.listeners[channel]) {
            for (const listener of this.listeners[channel]) {
                listener(data)
            }
        }
    }

    /**
     * Add listener on channel
     * @param channel
     * @param callback
     */
    on(channel, callback) {
        if (!this.listeners[channel]) this.listeners[channel] = []
        this.listeners[channel].push(callback)
    }

    /**
     * Remove listener on channel
     * @param channel
     * @param callback
     */
    removeListener(channel, callback) {
        if (!this.listeners[channel]) return
        this.listeners[channel] = this.listeners[channel].filter(l => l !== callback)
    }


    /**
     * Load users updates
     */
    async update() {
        try {

            // Check if the user is authenticated
            if (!this.app.context.app.auth().getUserId()) {
                return;
            }

            // Find a module with a socket URL
            const socketModule = Object.values(this.app.context?.a2u?.source?.modules || []).find((m) => !!m.socketUrl);
            if (!socketModule) return;

            // Load updates for the specified channel
            const {updates} = await this.app.context.app.client.call(
                `${socketModule.endpointUrl}/socket-updates`,
                'updates',
                "",
            );

            // Check if we have updates
            if (!updates?.length) return;

            // Add update to incoming data
            for (const update of updates) {
                this.addToIncomingData(update.channel, update?.payload || {});
            }

        } catch (e) {
            console.error('Error loading user updates:', e);
        }
    }
}
