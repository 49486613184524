import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

export class MarketingEventProcessor extends BaseComponentProcessor {
    /**
     * Process incoming event
     * @return {Promise<void>}
     */
    async processEvent() {
        if (this.app?.renderer?.a2u?.runMode !== "release") {
            return;
        }

        // Get marketing pixels plugin
        const plugin = this.context.renderer.a2u.getDevice()?.getPluginInstance?.("MarketingPixelsPlugin");

        if(!plugin) {
            return;
        }

        // Get event
        const event = this.block?.properties?.event || null;

        if (!event) {
            console.error('Event is not set');
            return;
        }

        // Emit event
        plugin.emit(event);
    }
}
