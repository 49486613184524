import {BaseComponentProcessor} from "../../utils/processor/BaseComponentProcessor";

/**
 * Code processor
 */
export class HasInAppPurchaseProcessor extends BaseComponentProcessor {

    /**
     * Process incoming event
     * @param event
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    async processEvent(event, data) {
        // Get products list
        const products = this.getProductsList();

        const targetPlatform = this.context.renderer.a2u.source.modules[this.context.renderer.a2u.getModuleId()]?.type === 'web' ? 'web' : 'mobile';

        // Debug list of goods for web environment
        if(this.context.renderer.a2u.getPlatform() === "web" && targetPlatform !== 'web') {
            return this.debugResponse({yes: {}, no: {}}, `User bought: ${products} ?`)
        }

        // Get ad instance
        const iapManager = this.context.renderer.a2u.getDevice()?.getPluginInstance?.("IAPManager");

        // Update store
        if (iapManager.getStoreState() === 'error') {
            await iapManager.update();

            if (iapManager.getStoreState() === 'error') {
                console.error("Error update store");

                // Log error
                this.logError(new Error("Error update store"));

                return this.processOutgoingLinks(this.context, this.block.id, undefined, "error");
            }
        }

        // Check if selected any product
        if(products === '*') {
            iapManager?.isSubscribed().then(result => {
                this.processOutgoingLinks(this.context, this.block.id, undefined, result ? "yes" : "no");
            }).catch(e => {
                console.error("Error check subscription", e);

                // Log error
                this.logError(e);

                this.processOutgoingLinks(this.context, this.block.id, undefined, "error");
            })
        } else {
            // Check all listed products
            iapManager.isOwned(products?.split(",")).then(result => {
                this.processOutgoingLinks(this.context, this.block.id, undefined, result ? "yes" : "no");
            }).catch(e => {
                console.error("Error check purchase", e);

                // Log error
                this.logError(e);

                this.processOutgoingLinks(this.context, this.block.id, undefined, "error");
            })
        }
    }

    /**
     * Retrieves the list of products based on the selected groups and fallback value.
     *
     * @returns {string} The list of product IDs or the fallback value if no products are found.
     */
    getProductsList() {
        // Get the fallback value
        const fallbackValue = this.context.interpretString(this.block?.properties?.products)?.trim();

        // Get the selected groups
        const selectedGroups = this.block?.properties?.groups;

        // Return the fallback value if no groups are selected
        if (!selectedGroups?.length) {
            return fallbackValue;
        }

        // Get the list of products
        const products = this.context?.renderer?.a2u?.source?.iap?.products || [];

        // Return the fallback value if no products are found
        if (!products.length) {
            return fallbackValue;
        }

        // Filter the products based on the selected groups and return the product IDs
        return products.filter((p) => selectedGroups.includes(p.group)).map((p) => p.productId).join(',');
    }
}
