<template>

  <ab-flow-base-cmp :block="block" class="date-editor-cmp" :class="classesString" :style="stylesString">
    <q-input
      class="full-width"
      v-model="localValue"
      mask="date"
      :rules="validationRules"
      lazy-rules
      hide-bottom-space
      v-bind="dynamicProps"
    >
      <template v-slot:append>
        <q-icon name="event" class="date-pick">
          <q-popup-proxy cover transition-show="scale" transition-hide="scale">
            <q-date v-model="localValue" :title="title">
              <div class="row items-center justify-end">
                <q-btn v-close-popup label="Close" flat />
              </div>
            </q-date>
          </q-popup-proxy>
        </q-icon>
      </template>
    </q-input>
  </ab-flow-base-cmp>

</template>

<script>
import moment from 'moment';
import {renderMixins} from "../renderMixins";
import AbFlowBaseCmp from "../Containers/Designer/AbFlowBaseCmp.vue";
import {formComponentsMixins} from "./formComponentsMixins";
import {formDefaultValueMixin} from "./formDefaultValueMixin";
import validatableFormFieldMixin from './validatableFormFieldMixin';

export default {
  components: {AbFlowBaseCmp},
  mixins: [renderMixins, formComponentsMixins, validatableFormFieldMixin, formDefaultValueMixin],
  props: ['block'],
  name: "DatePickerEditorCmp",

  data() {
    return {
      localValue: null,
    };
  },

  computed: {
    /**
     * Title
     * @return {string}
     */
    title() {
      return this.block?.properties?.title || "";
    },
  },

  watch: {
    /**
     * Watch for changes in the selected date and update the current value accordingly.
     */
    localValue() {
      this.currentValue = moment(this.localValue, 'YYYY/MM/DD').unix();
    },
  },

  mounted() {
    this.$watch('currentValue', (currentValue) => {
      currentValue = parseInt(currentValue);
      if (currentValue) {
        this.localValue = moment(1000 * currentValue).format('YYYY/MM/DD');
      }
    }, {immediate: true});
  },
}

</script>


<style lang="scss">

.date-editor-cmp {
  position: relative;

  .links-wrapper {
    right: 0;
    left: auto;
  }

  .date-pick {
    color: var(--control-text-color);
  }

  .q-field {
    .q-field__messages {
      color: var(--control-text-color);
    }

    &.q-field--error {
      .q-field__messages, .text-negative {
        color: var(--foreground-color-validation-error, #c10015) !important;
      }
    }

    .q-field__control {
      color: var(--control-focus-color);
      height: 100%;
      margin-left: 5px;
      margin-right: 5px;

      .q-field__native, .q-field__label {
        color: var(--control-text-color) !important;
      }
    }

    &.q-field--error {
      padding-bottom: 20px;

      .q-field__bottom {
        position: absolute;
      }
    }
  }
}


</style>
