/**
 * Mixin to handle default values for form components.
 */
export const formDefaultValueMixin = {
  /**
   * Lifecycle hook called when the component is created.
   * Checks if the block has a default value and sets it to the component's current value if not already set.
   */
  created() {
    if (this.block?.properties?.defaultValue) {
      const defaultValue = this.getValue(this.block?.properties?.defaultValue) || undefined;

      if (!this.currentValue) {
        this.currentValue = defaultValue;
      }

      this.defaultValue = defaultValue;
    }
  },
};
