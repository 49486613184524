import {BaseComponentProcessor} from './BaseComponentProcessor';

/**
 * BaseModalProcessor class extends BaseComponentProcessor to handle modal display logic.
 */
export class BaseModalProcessor extends BaseComponentProcessor {
  /**
   * Displays a modal with the specified properties.
   */
  showModal(params) {
    this.context.currentDiagram?.showModal(this.block.id, {
      type: "modal",
      isPersistent: this.block?.properties?.isPersistent ? true : undefined,
      noBackdrop: this.block?.properties?.noBackdrop === 1 ? true : undefined,
      modalWidth: this.block?.properties?.modalWidth || "auto",
      modalHeight: this.block?.properties?.modalHeight || "auto",
      position: this.block?.properties?.modalPosition || "center",
      incomingParams: params,
      transitionShow: this.block?.properties?.transitionShow || 'fade',
      transitionHide: this.block?.properties?.transitionHide || 'fade',
      transitionDuration: this.block?.properties?.transitionDuration || 300,
      overlayColor: this.block?.properties?.overlayColor || undefined,
    });
  }
}
